// 
// general.scss
// Theme general elements and helper styling


// plyr and GLightbox theme
.plyr__control--overlaid, 
.plyr--audio .plyr__control.plyr__tab-focus, 
.plyr--audio .plyr__control:hover, 
.plyr--audio .plyr__control[aria-expanded=true],
.plyr--video .plyr__control.plyr__tab-focus, 
.plyr--video .plyr__control:hover, 
.plyr--video .plyr__control[aria-expanded=true],
.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]:before{
  background: $primary;
}
.plyr--full-ui input[type=range]{
  color: $primary;
}


/* rtl:raw:
.glightbox-clean .gclose, .glightbox-modern .gclose{
  right: auto !important;
  left: 20px;
}
*/

// Search
.nav-search .dropdown-menu{
  top: 120%;
  visibility: hidden;
  opacity: 0;
  display: block;
  transition: $transition-base;
  width: 300px;
  left: auto;
  right: 0;
}
.nav-search .dropdown-menu.show{
  top: 100%;
  visibility: visible;
  opacity: 1;
}
@include media-breakpoint-down(sm) {
  .nav-search .dropdown-menu{
    width: 100%;
  }
}

// Back to top
.back-top {
  cursor: pointer;
  position: fixed !important;
  bottom: 40px;
  right: 40px;
  display: block;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transform: translateY(50%);
  transition: $transition-base;
  background: rgba($primary, $soft-alpha + 0.1);
  color: $primary;
  border-radius: 10%;
  width: 52px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  i{
    font-size: 1.6rem;
    vertical-align: middle;
  }
  &:hover{
    background: $primary;
    color: $white;
  }
  &.back-top-show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
  }
}
@include media-breakpoint-down(md) {
  .back-top {
    bottom: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    i{
      font-size: 1rem;
    }
  }
}

// To flip element in RTL
/* rtl:raw:
  .rtl-flip {
    transform: scaleX(-1);
  }
  .rtl-flip.bi:before{
    transform: inherit;
  }
*/

// Ribbon css
.ribbon{
  position: absolute;
  top: 15px;
  left: 0;
  background: $white;
  padding: 0 10px;
  color:$dark;
  box-shadow: 0 0 40px rgba(0, 0, 0, .15);
}
.ribbon::before{
  content: "";
  position: absolute;
  top: 0px;
  right: -20px;
  border-top: 0.746em solid #fff;
  border-bottom: 0.746em solid #fff;
  border-right: 20px solid transparent;
  border-left: 0px solid transparent;
}

//Upload remove button
.uploadremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: 0;
  padding: 0;
  background: #dc3545;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transition: $transition-base;
}

// Sticky element
.sticky-element {
  position: fixed;
  bottom: 50px;
  left: 50px;
  width: 300px;
  visibility: hidden;
  z-index: 1000;
  opacity: 0;
  transform: translateX(-50%);
  transition: $transition-base;
}

.sticky-element.sticky-element-sticked {
  visibility: visible;
  opacity: 1;
  transform: translateX(0%);
}

// Quick fix for bootstrap font width
.bi.fa-fw{
  display: inline-block;
}

// pre loader
.preloader {
  background-color: $white;
  position: fixed;
  z-index: 1190;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  .preloader-item {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}


// Radio button css for soft success color
.btn-success-soft-check{
  background-color: $light;
  text-align: start;
}

.btn-success-soft-check:hover {
  background-color: rgba($success, 0.2);
  border: $border-width solid $success;
}
.btn-check:active+.btn-success-soft-check, .btn-check:checked+.btn-success-soft-check, .btn-success-soft-check.active, .btn-success-soft-check:active, .show>.btn-success-soft-check.dropdown-toggle {
  background-color: rgba($success, 0.2);
  color: none;
  border: $border-width solid $success !important;
}
.btn-check:focus + .btn, .btn-round-shadow:focus {
  box-shadow: none;
}

// Radio button css for success color
.btn-primary-check{
  border:$border-width solid $secondary;
}

.btn-primary-check:hover {
  background-color:$primary;
  color: $white;
  border:$border-width solid $primary;
}
.btn-check:active+.btn-primary-check, .btn-check:checked+.btn-primary-check, .btn-primary-check.active, .btn-primary-check:active, .show>.btn-primary-check.dropdown-toggle {
  background-color:$primary;
  color: $white;
  border:$border-width solid $primary;
}
.btn-check:focus + .btn, .btn-round-shadow:focus {
  box-shadow: none;
}